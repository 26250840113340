<!--
 * @Author: 侯怀烨
 * @Date: 2021-01-05 14:06:56
 * @LastEditTime: 2021-05-13 14:04:48
 * @LastEditors: Please set LastEditors
 * @Description: 添加报损单
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\Lossreport\components\AddLossre.vue
-->
<template>
  <div>
    <el-dialog
      fullscreen
      :modal="false"
      :title="title"
      center
      :visible.sync="showLossre"
      :close-on-click-modal="false"
    >
      <div>
        <el-row>
          <el-form :model="form">
            <el-form-item>
              <el-select v-model="form.depot_id" style="width: 120px">
                <el-option
                  v-for="(item, index) in warehouse"
                  :key="index"
                  :label="item.depot_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-row>
        <el-table stripe :data="tableData" height="360px">
          <el-table-column type="index" width="60"></el-table-column>
          <el-table-column
            v-for="(list, index) in colemd"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          >
            <template
              v-if="list.label == '商品名称'"
              #default="{ $index, row }"
            >
              <goods-search
                :f-key="row.goods_name"
                :depot-id="String(form.depot_id)"
                @add-rows="addRows"
                @select-goods-all="selectGoods($event, row, $index)"
              ></goods-search>
            </template>
            <template v-else-if="list.label == '生产日期'" #default="{ row }">
              <el-date-picker
                v-model="row.goodsDate"
                style="width: 140px"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </template>
            <template v-else-if="list.label == '申请数量'" #default="{ row }">
              <el-input v-model="row.apply_read_num" style="width: 80px" />
            </template>
            <template v-else-if="list.label == '单价'" #default="{ row }">
              <el-input v-model="row.goodsPrice"></el-input>
            </template>
            <template v-else-if="list.label == '金额'" #default="{ row }">
              <!-- <el-input v-model="row.goodsPrice"></el-input> -->
              {{
                (Number(row.goodsPrice) * Number(row.apply_read_num)).toFixed(2)
              }}
            </template>
            <template v-else-if="list.label == '单位'" #default="{ row }">
              <el-select
                v-model="row.unitID"
                placeholder="选择单位"
                style="width: 70px"
                @change="unitChange($event, row)"
              >
                <el-option
                  v-for="item in row.arr_unit"
                  :key="item.id"
                  :label="item.unit_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>
            <!-- <template v-else-if="list.label == '金额'" #default="{ row }">
              {{ Number(row.quantity * row.goods_price).toFixed(2) }}
            </template> -->
            <template v-else-if="list.label == '备注'" #default="{ row }">
              <el-input v-model="row.Remarks" style="width: 80px" />
              <!-- onkeyup="value=value.replace(/[^\d.]/g,'') " -->
            </template>
            <template v-else #default="{ row }">
              {{ row[list.prop] }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="120"
          >
            <template #default="{ row, $index }">
              <el-button type="text" @click="handlerAdd(row, $index)">
                添加
              </el-button>
              <el-button type="text" @click="handlerDelet(row, $index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保 存</el-button>
        <el-button @click="showLossre = !showLossre">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    components: {
      GoodsSearch,
    },
    data() {
      return {
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          Add: '/depotAdmin/bill-loss/add',
        },
        form: {
          depot_id: '',
        },
        warehouse: [],
        title: '添加报损单',
        showLossre: false,
        tableData: [
          {
            goodsId: '',
            arr_unit: [],
            goodsName: '',
            apply_read_num: '',
            goodsPrice: '',
            goodsSpec: '',
            goodsUnit: '',
            unitID: '',
            goodsDate: '',
            brandId: '',
            Remarks: '',
            brand_name: '',
          },
        ],
        colemd: [
          {
            order: 1,
            label: '品牌',
            align: 'center',
            prop: 'brand_name',
            width: '80',
          },
          {
            order: 2,
            label: '商品名称',
            align: 'center',
            prop: 'goodsName',
            width: '220',
          },
          {
            order: 3,
            label: '单位',
            align: 'center',
            prop: 'unitID',
            width: '80',
          },
          {
            order: 3.5,
            label: '单价',
            align: 'center',
            prop: 'goodsPrice',
            width: '150',
          },
          {
            order: 4,
            label: '申请数量',
            align: 'center',
            prop: 'apply_read_num',
            width: '100',
          },
          {
            order: 4.5,
            label: '金额',
            align: 'center',
            prop: 'money',
            width: '100',
          },
          {
            order: 5,
            label: '生产日期',
            align: 'center',
            prop: 'goodsDate',
            width: '170',
          },
          {
            order: 6,
            label: '备注',
            align: 'center',
            prop: 'Remarks',
            width: '120',
          },
        ],
      }
    },
    watch: {
      showLossre(val) {
        if (!val) {
          this.tableData = [
            {
              goodsId: '',
              brand_name: '',
              arr_unit: [],
              goodsName: '',
              apply_read_num: '',
              goodsPrice: '',
              goodsSpec: '',
              goodsUnit: '',
              unitID: '',
              goodsDate: '',
              brandId: '',
              Remarks: '',
            },
          ]
          this.form.depot_id = ''
        }
      },
    },
    mounted() {
      this.handlerwarehouse()
    },
    methods: {
      handlerAdd(row, $index) {
        this.tableData.push({
          goodsId: '',
          brand_name: '',
          arr_unit: [],
          goodsName: '',
          apply_read_num: '',
          goodsPrice: '',
          goodsSpec: '',
          goodsUnit: '',
          unitID: '',
          goodsDate: '',
          brandId: '',
          Remarks: '',
        })
      },
      handlerDelet(row, $index) {
        if (this.tableData.length > 1) {
          this.tableData.splice($index, 1)
        } else {
          this.$message({
            message: '不能删除最后一个',
            type: 'error',
          })
        }
      },
      handlersave() {
        var Datas = {
          depot_id: this.form.depot_id,
          goods: JSON.parse(JSON.stringify(this.tableData)),
        }
        Datas.goods.forEach((item) => {
          console.log(item, '')
          delete item.arr_unit
        })
        Datas.goods = JSON.stringify(Datas.goods)
        console.log(this.tableData)
        let end = JSON.parse(JSON.stringify(this.tableData))
        let endData = []
        end.forEach((i) => {
          if (i.brandId) {
            endData.push(i)
          }
        })
        console.log('ee', endData)
        if (endData.length == 0) {
          this.$message.error('请添加商品')
        } else {
          Datas.goods = JSON.stringify(endData)
          console.log(Datas, '报损商品')
          postAction(this.url.Add, Datas)
            .then((res) => {
              console.log(res)
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.showLossre = false
              this.$emit('getlist')
            })
            .catch((err) => {
              console.log(err, '')
              this.$message({
                message: res.msg,
                type: 'error',
              })
            })
        }
      },
      addRows(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var data = {
              brand_name: item.brand_name,
              goodsId: item.goods_id,
              arr_unit: item.arr_unit,
              goodsName: item.goods_name,
              goods_name: item.goods_name,
              // apply_read_num: item.depot_stock_num,
              apply_read_num: '',
              goodsPrice: item.goods_price,
              goodsSpec: item.specs,
              goodsUnit: item.unit_name,
              unitID: item.unit_id,
              goodsDate: item.goods_production_date,
              brandId: item.brand_id,
              Remarks: '',
            }
            item.goodsName = item.goods_name
            // this.tableData.splice(this.tableData.length - 1, 0, data)
            // this.tableData.push(data)
            data.arr_unit.forEach((unit, idx) => {
              if (data.unitID == unit.unit_id) {
                let a = data.arr_unit[idx].before_price
                if (a) {
                  data.goodsPrice = data.arr_unit[idx].before_price
                } else {
                  data.goodsPrice = data.arr_unit[idx].cost_price
                }
              }
            })
            this.tableData.unshift(data)
          })
          this.$message.success('添加成功')
        }
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        let id = row.arr_unit.filter((item) => item.id == e)[0].unit_id
        row.goodsPrice = price
        row.goodsUnit = name
        row.unitID = id
        row.arr_unit.forEach((unit, idx) => {
          if (row.unitID == unit.unit_id) {
            let a = row.arr_unit[idx].before_price
            if (a) {
              row.goodsPrice = row.arr_unit[idx].before_price
            } else {
              row.goodsPrice = row.arr_unit[idx].cost_price
            }
          }
        })
        console.log('单位', row.unitID)
      },
      selectGoods(event, row, index) {
        var data = {
          brand_name: event.brand_name,
          goodsId: event.goods_id,
          arr_unit: event.arr_unit,
          goodsName: event.goods_name,
          // apply_read_num: event.depot_stock_num,
          apply_read_num: '',
          goodsPrice: event.goods_price,
          goodsSpec: event.specs,
          goodsUnit: event.unit_name,
          unitID: event.unit_id,
          goodsDate: event.goods_production_date,
          brandId: event.brand_id,
          Remarks: '',
        }
        Object.assign(row, data)
        row.arr_unit.forEach((unit, idx) => {
          if (row.unitID == unit.unit_id) {
            let a = row.arr_unit[idx].before_price
            if (a) {
              row.goodsPrice = row.arr_unit[idx].before_price
            } else {
              row.goodsPrice = row.arr_unit[idx].cost_price
            }
          }
        })
        console.log(row)
      },
    },
  }
</script>

<style></style>
