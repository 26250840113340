<!--
 * @Author: 侯怀烨
 * @Date: 2021-01-04 17:41:45
 * @LastEditTime: 2021-03-29 10:42:52
 * @LastEditors: Please set LastEditors
 * @Description: 报损单
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\Lossreport\index.vue
-->
<template>
  <!-- NAME[epic=库管] 报损单 -->
  <div style="padding: 20px">
    <el-row>
      <el-col :span="22">
        <el-form inline>
          <el-form-item>
            <el-select
              v-model="form.depot_id"
              style="width: 120px"
              placeholder="请选择仓库"
            >
              <el-option
                v-for="(item, index) in warehouse"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-select
              v-model="form.loss_status"
              clearable
              style="width: 140px"
              placeholder="请选择状态"
            >
              <el-option
                v-for="(i, idx) in statusSelect"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-select
              v-model="form.time_type"
              clearable
              style="width: 140px"
              placeholder="请选择申请时间"
            >
              <el-option
                v-for="(i, idx) in timeSelect"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.keyword"
              placeholder="输入单号、商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerUpdata">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerExport">导 出</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="handlerAdd">添 加</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe :data="tableData">
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div
              v-if="list.label == '单号'"
              class="underline"
              @click="handlerlink(row, 1)"
            >
              {{ row[list.prop] }}
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right" align="center">
          <template #default="{ row }">
            <el-button type="text" @click="handlerlink(row, 1)">查看</el-button>
            <el-button
              v-show="row.loss_status != 2 && row.loss_status_text != '已作废'"
              type="text"
              @click="handlerlink(row, 2)"
            >
              审核
            </el-button>
            <el-button type="text" @click="handlePrint(row)">打印</el-button>
            <el-popconfirm
              v-show="row.loss_status != 3"
              icon="el-icon-info"
              icon-color="red"
              title="确定作废吗？"
              @confirm="deleterouter(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                作废
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-lossre ref="addlossre" @getlist="getlist"></add-lossre>
    <goodslink ref="link" @getlist="getlist"></goodslink>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="BS"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </div>
</template>

<script>
  import AddLossre from './components/AddLossre.vue'
  import { downloadFile, postAction } from '@/api/Employee'
  import goodslink from './components/goodslink.vue'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { day_30 } from '@/utils/Time'
  export default {
    components: {
      AddLossre,
      goodslink,
      RuilangDisplayDialog,
    },
    data() {
      return {
        data_id: 0,
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          list: '/depotAdmin/bill-loss/bill-list',
          delete: 'depotAdmin/bill-loss/scrap',
        },
        time: [],
        warehouse: [],
        form: {
          loss_type: 1, //报损单1
          start_time: '',
          end_time: '',
          depot_id: '',
          keyword: '',
          pageNo: 1,
          pageSize: 10,
          time_type: 1,
          loss_status: null,
        },
        statusSelect: [
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 2,
            name: '已审核',
          },
          {
            id: 3,
            name: '已作废',
          },
        ],
        timeSelect: [
          {
            id: 1,
            name: '申请时间',
          },
          {
            id: 2,
            name: '审核时间',
          },
        ],
        total: 0,
        tableData: [],
        colemd: [
          {
            label: '单号',
            align: 'center',
            prop: 'loss_code',
            width: '200',
          },
          {
            label: '仓库',
            align: 'center',
            prop: 'depot_name',
            width: '120',
          },
          {
            label: '金额',
            align: 'center',
            prop: 'total_amount',
            width: '100',
          },
          {
            label: '状态',
            align: 'center',
            prop: 'loss_status_text',
            width: '100',
          },
          {
            label: '申报人',
            align: 'center',
            prop: 'add_name',
            width: '100',
          },
          {
            label: '申请时间',
            align: 'center',
            prop: 'create_at',
            width: '130',
          },
          {
            label: '审核人',
            align: 'center',
            prop: 'check_name',
            width: '100',
          },
          {
            label: '审核时间',
            align: 'center',
            prop: 'check_time',
            width: '130',
          },
          {
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '130',
          },
        ],
      }
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.initTime()
      this.handlerwarehouse()
      this.handlerUpdata()
    },
    methods: {
      handlerExport() {
        const data = {
          loss_type: this.form.loss_type,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          depot_id: this.form.depot_id,
          keyword: this.form.keyword,
          time_type: this.form.time_type,
          loss_status: this.form.loss_status,
        }
        downloadFile(
          'depotAdmin/bill-loss/bill-list-export',
          '报损单.xlsx',
          data
        ).then(() => {
          this.bigLoading = false
        })
      },
      initTime() {
        this.form.start_time = day_30()[0]
        this.form.end_time = day_30()[1]
        this.time = day_30()
      },
      deleterouter(row) {
        postAction(this.url.delete, { bill_id: row.id })
          .then((res) => {
            console.log(res, '')
            this.$message({ message: res.msg, type: 'success' })
            this.handlerlist()
          })
          .catch((err) => {
            this.$message({ message: err.msg, type: 'success' })
          })
      },
      getlist() {
        this.handlerlist()
      },
      handlerAdd() {
        this.$refs.addlossre.showLossre = true
      },
      handlerlink(row, type) {
        console.log(row, '查看')
        if (type == 1) {
          // 查看
          this.$refs.link.showlink = true
          this.$refs.link.isEdit = true
          this.$refs.link.handlerinfo(row)
        } else {
          this.$refs.link.showlink = true
          this.$refs.link.isEdit = false
          this.$refs.link.handlerinfo(row)
        }
      },
      handlerUpdata() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerUpdata()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      //报损单列表
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res, '')
            this.total = res.totalCount
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlePrint(row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style></style>
