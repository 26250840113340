var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            fullscreen: "",
            modal: false,
            title: _vm.title,
            center: "",
            visible: _vm.showLossre,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLossre = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.form } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px" },
                              model: {
                                value: _vm.form.depot_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "depot_id", $$v)
                                },
                                expression: "form.depot_id",
                              },
                            },
                            _vm._l(_vm.warehouse, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.depot_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.tableData, height: "360px" } },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "60" },
                  }),
                  _vm._l(_vm.colemd, function (list, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: list.label,
                        align: list.align,
                        prop: list.prop,
                        width: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          list.label == "商品名称"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    _c("goods-search", {
                                      attrs: {
                                        "f-key": row.goods_name,
                                        "depot-id": String(_vm.form.depot_id),
                                      },
                                      on: {
                                        "add-rows": _vm.addRows,
                                        "select-goods-all": function ($event) {
                                          return _vm.selectGoods(
                                            $event,
                                            row,
                                            $index
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              }
                            : list.label == "生产日期"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "140px" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: row.goodsDate,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goodsDate", $$v)
                                        },
                                        expression: "row.goodsDate",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : list.label == "申请数量"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      staticStyle: { width: "80px" },
                                      model: {
                                        value: row.apply_read_num,
                                        callback: function ($$v) {
                                          _vm.$set(row, "apply_read_num", $$v)
                                        },
                                        expression: "row.apply_read_num",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : list.label == "单价"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      model: {
                                        value: row.goodsPrice,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goodsPrice", $$v)
                                        },
                                        expression: "row.goodsPrice",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : list.label == "金额"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            Number(row.goodsPrice) *
                                            Number(row.apply_read_num)
                                          ).toFixed(2)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              }
                            : list.label == "单位"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "70px" },
                                        attrs: { placeholder: "选择单位" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.unitChange($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.unitID,
                                          callback: function ($$v) {
                                            _vm.$set(row, "unitID", $$v)
                                          },
                                          expression: "row.unitID",
                                        },
                                      },
                                      _vm._l(row.arr_unit, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.unit_name,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              }
                            : list.label == "备注"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      staticStyle: { width: "80px" },
                                      model: {
                                        value: row.Remarks,
                                        callback: function ($$v) {
                                          _vm.$set(row, "Remarks", $$v)
                                        },
                                        expression: "row.Remarks",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(" " + _vm._s(row[list.prop]) + " "),
                                  ]
                                },
                              },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      fixed: "right",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerAdd(row, $index)
                                  },
                                },
                              },
                              [_vm._v(" 添加 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerDelet(row, $index)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showLossre = !_vm.showLossre
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }