var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 22 } },
            [
              _c(
                "el-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "请选择仓库" },
                          model: {
                            value: _vm.form.depot_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depot_id", $$v)
                            },
                            expression: "form.depot_id",
                          },
                        },
                        _vm._l(_vm.warehouse, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.depot_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "请选择状态" },
                          model: {
                            value: _vm.form.loss_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "loss_status", $$v)
                            },
                            expression: "form.loss_status",
                          },
                        },
                        _vm._l(_vm.statusSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择申请时间",
                          },
                          model: {
                            value: _vm.form.time_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time_type", $$v)
                            },
                            expression: "form.time_type",
                          },
                        },
                        _vm._l(_vm.timeSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "-",
                          "value-format": "yyyy-MM-dd",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.time,
                          callback: function ($$v) {
                            _vm.time = $$v
                          },
                          expression: "time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入单号、商品名称" },
                        model: {
                          value: _vm.form.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "keyword", $$v)
                          },
                          expression: "form.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerUpdata },
                        },
                        [_vm._v("查 询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerExport },
                        },
                        [_vm._v("导 出")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
                [_vm._v("添 加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.tableData } },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center" },
              }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            list.label == "单号"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "underline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerlink(row, 1)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                                )
                              : _c("div", [_vm._v(_vm._s(row[list.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "150",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerlink(row, 1)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.loss_status != 2 &&
                                  row.loss_status_text != "已作废",
                                expression:
                                  "row.loss_status != 2 && row.loss_status_text != '已作废'",
                              },
                            ],
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerlink(row, 2)
                              },
                            },
                          },
                          [_vm._v(" 审核 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePrint(row)
                              },
                            },
                          },
                          [_vm._v("打印")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.loss_status != 3,
                                expression: "row.loss_status != 3",
                              },
                            ],
                            attrs: {
                              icon: "el-icon-info",
                              "icon-color": "red",
                              title: "确定作废吗？",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleterouter(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(" 作废 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("add-lossre", { ref: "addlossre", on: { getlist: _vm.getlist } }),
      _c("goodslink", { ref: "link", on: { getlist: _vm.getlist } }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "BS", data_id: _vm.data_id, type: 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }